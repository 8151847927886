//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { versionPage } from '@/api/modular/fileStream/helpCenter'
import { STable } from '@/components'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import moment from 'moment'
import addNote from './components/addNote.vue'
// import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
const columns = [
    {
        title: '版本',
        dataIndex: 'title',
        ellipsis: true,
        width: 250,
    },
    {
        title: '更新記錄',
        dataIndex: 'contentList',
        scopedSlots: { customRender: 'contentList' },
    },
]
export default {
    components: {
        OpenData,
        STable,
        addNote,
    },
    data() {
        return {
            columns,
            queryParam: {},
            loadData: (parameter) => {
                return versionPage({ ...parameter, ...this.queryParam })
                    .then((res) => res.data)
                    .catch(() => {
                        return new Promise((resolve, reject) => {
                            resolve({
                                pageNo: 1,
                                pageSize: 10,
                                totalPage: 0,
                                totalRows: 0,
                                rows: [],
                                rainbow: [],
                            })
                        })
                    })
            },
        }
    },
    methods: {
        onSearch() {
            this.$refs.table.refesh(true)
        },
        formatDate(text) {
            if (!text) return ''
            return moment(text).format('YYYY-MM-DD hh:mm:ss')
        },
        getTagName(text) {
            switch (text) {
                case 1:
                    return '收文登記'
                case 2:
                    return '快速發文'
                case 3:
                    return '擬稿發文'
            }
        },
    },
}
