//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { versionAdd } from '@/api/modular/fileStream/helpCenter'
export default {
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      tags: [{ value: '' }]
    }
  },
  methods: {
    handleCancel() {
      this.tags = [{ value: '' }]
      this.form.resetFields()
      this.visible = false
    },
    open() {
      this.visible = true
    },
    add() {
      this.tags.push({ value: '' })
    },
    handleBlur() {
      this.form.setFieldsValue({ tags: this.tags.map(j => j.value).join() })
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          versionAdd({
            'contentList': this.tags.map(j => j.value),
            'title': values.tagsName
          })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('添加成功')
              this.$emit('success')
              this.handleCancel()
            }
          })
        }
      })
    },
    del(index) {
      this.tags.splice(index, 1)
    }
  }
}
